import * as web3 from '@solana/web3.js'
import { RAYDIUM_V4_PROGRAM_ID, type ExtendedLiquidityStateV4, type ExtendedPoolInfoV4 } from './interface'
import { liquidityStateV4Layout, SPL_MINT_LAYOUT } from '@raydium-io/raydium-sdk-v2'
import type { SolanaTokenPair } from '@turbx/common'

// https://github.com/precious-void/raydium-swap/blob/main/src/RaydiumSwap.ts#L71

const _getProgramAccounts = async (
  connection: web3.Connection,
  baseMint: string,
  quoteMint: string
): Promise<web3.GetProgramAccountsResponse> => {
  const layout = liquidityStateV4Layout

  return await connection.getProgramAccounts(new web3.PublicKey(RAYDIUM_V4_PROGRAM_ID), {
    filters: [
      { dataSize: layout.span },
      {
        memcmp: {
          offset: layout.offsetOf('baseMint'),
          bytes: new web3.PublicKey(baseMint).toBase58()
        }
      },
      {
        memcmp: {
          offset: layout.offsetOf('quoteMint'),
          bytes: new web3.PublicKey(quoteMint).toBase58()
        }
      }
    ]
  })
}

export const getProgramAccounts = async (
  connection: web3.Connection,
  baseMint: string,
  quoteMint: string
): Promise<ExtendedLiquidityStateV4 | null> => {
  const response = await Promise.all([
    _getProgramAccounts(connection, baseMint, quoteMint),
    _getProgramAccounts(connection, quoteMint, baseMint)
  ])

  const result = response.find((r) => r.length > 0)
  if (!result) return null

  if (result.length !== 1) {
    console.warn('Unexpected pool length', result, baseMint)
  }

  const data = result[0]!

  return {
    pair: data.pubkey.toBase58() as SolanaTokenPair,
    ...formatLiquidityState(data.account.data)
  }
}

const formatLiquidityState = (data: Buffer): Omit<ExtendedLiquidityStateV4, 'pair'> => {
  const layout = liquidityStateV4Layout

  // const poolData = PoolInfoLayout.decode(data)
  // const price = SqrtPriceMath.sqrtPriceX64ToPrice(
  //   poolData.sqrtPriceX64,
  //   poolData.mintDecimalsA,
  //   poolData.mintDecimalsB
  // ).toJSON()

  return { ...layout.decode(data) }
}

export const getMintsByPair = async (
  connection: web3.Connection,
  pair: SolanaTokenPair
): Promise<ExtendedLiquidityStateV4> => {
  const account = await connection.getAccountInfo(new web3.PublicKey(pair))
  if (account === null) throw Error(`get id info error ${pair}`)

  return { pair, ...formatLiquidityState(account.data) }
}

// https://github.com/warp-id/solana-trading-bot/blob/master/filters/burn.filter.ts#L11
// https://github.com/warp-id/solana-trading-bot/pull/124
// https://docs.shyft.to/solana-indexers/case-studies/raydium/get-pool-burn-percentage
// https://github.com/zerofill/Sniper-Solana/blob/main/buy.ts#L262

// burned 100% DEQoozEyffRXBzS2x28vbZQaMhHfWHUdjAvGPRhmgbcV -> A7v2SHz9opWpEiDLQp8UjAus9iW4ioir9sBUuRNBmP3J
// burned 0% FfZ9EmoeLsrQUMb4Aoy2BkmgnHoyGbYFBKghWK1J7wSD -> HzGrL18CZC9pv1FTUxUJUGA4n6fxVd9KawYXcyErYPr
// burned 99.96% 2aPsSVxFw6dGRqWWUKfwujN6WVoyxuhjJaPzYaJvGDDR -> 4qSHebVRJrRUHReCRtYKyQbzAPNh1daeJhUpuk4dawp1
// burned 99.91% 6sFwxoTw4rwuJgejiKT7tCSqfyXTQ1k4P8vardWWUhug -> AjQYoKarCzebSbc5r9PmzX6CimHtLCzBdNofEndVdPRn

/*
This is taken from Raydium's FE code
https://github.com/raydium-io/raydium-frontend/blob/master/src/pages/liquidity/add.tsx#L646
*/
const getBurnPercentage = (lpReserve: number, actualSupply: number): number => {
  const maxLpSupply = Math.max(actualSupply, lpReserve - 1)
  const burnAmt = maxLpSupply - actualSupply
  if (maxLpSupply === 0) return 0
  return (burnAmt / maxLpSupply) * 100
}

export const formatAmmKeysById = async (connection: web3.Connection, pair: string): Promise<ExtendedPoolInfoV4> => {
  const account = await connection.getAccountInfo(new web3.PublicKey(pair))
  if (account === null) throw Error(`get id info error ${pair}`)
  const info = liquidityStateV4Layout.decode(account.data)

  const accountsInfo = await connection.getMultipleAccountsInfo([info.marketId, info.lpMint])
  const [marketAccount, lpMintAccount] = accountsInfo

  const marketId = info.marketId
  // const marketAccount = await this.connection.getAccountInfo(marketId)
  if (!marketAccount) throw Error(`get market info error ${marketId}`)

  const lpMint = info.lpMint
  // const lpMintAccount = await this.connection.getAccountInfo(lpMint)
  if (!lpMintAccount) throw Error(`get lp mint info error: ${lpMint}`)
  const lpMintInfo = SPL_MINT_LAYOUT.decode(lpMintAccount.data)

  const lpReserve = info.lpReserve.toNumber() / Math.pow(10, lpMintInfo.decimals)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actualSupply = (lpMintInfo.supply as any) / Math.pow(10, lpMintInfo.decimals)
  const burnPct = getBurnPercentage(lpReserve, actualSupply)

  return {
    lpBurnedPercentage: burnPct
  }
}
