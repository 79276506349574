import * as web3 from '@solana/web3.js'
import type { ExtendedRawMint } from './interface'
import { MintLayout } from '@solana/spl-token'
import type { SolanaTokenMint } from '@turbx/common'

export const mintSupply = async (connection: web3.Connection, mint: SolanaTokenMint): Promise<web3.TokenAmount> => {
  return (await connection.getTokenSupply(new web3.PublicKey(mint))).value
}

// https://github.com/warp-id/solana-trading-bot/pull/124/files
export const mintState = async (connection: web3.Connection, mint: SolanaTokenMint): Promise<ExtendedRawMint> => {
  const accountInfo = await connection.getAccountInfo(new web3.PublicKey(mint))

  if (accountInfo === null) throw new Error(`get mint info error ${mint}`)

  const state = MintLayout.decode(accountInfo.data)

  return {
    ...state,
    isRenounced: state.freezeAuthorityOption === 0,
    isFreezed:
      state.freezeAuthorityOption === 0 &&
      (state.freezeAuthority === null || state.freezeAuthority.equals(web3.PublicKey.default))
  }
}
