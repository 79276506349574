import * as web3 from '@solana/web3.js'
import { KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY, SOLANA_FRONTEND_HTTP_ENDPOINT } from '@/constant'
import { SolanaBaseClient } from '@turbx/solana'

export const SolanaGlobalClient = new SolanaBaseClient({
  logger: {
    trace: console.trace,
    debug: console.debug,
    info: console.info,
    warn: console.warn,
    error: console.error,
    fatal: console.error
  },
  connection: new web3.Connection(SOLANA_FRONTEND_HTTP_ENDPOINT, 'confirmed'),
  earlyAccessMintPublicKey: KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY
})
