import { useState, type FC } from 'react'
import { Icon } from '@turbx/deprecated-design-system'
import { Trans, useTranslation } from 'react-i18next'
import { useUserAuthentication } from '@/stores'
import { useModal, Modal } from '../deprecated-components'
import { html } from '@/assets/docs/term_of_use.md'
import { Box, Container, Float, HStack, VStack } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'
import bgEffectImage from './assets/sign-in/bg-effect.png'
import connectImage from './assets/sign-in/connect.png'
import { vstack } from '@/styled-system/patterns'
import { captureException } from '@sentry/react'

export const SignIn: FC = () => {
  const { t } = useTranslation()

  const agreementModal = useModal()

  const [isPending, setIsPending] = useState(false)
  const { signIn } = useUserAuthentication()

  return (
    <>
      <Modal modalId={agreementModal.modalId}>
        <Box w="90vw" maxH="80vh" mx="auto" overflow="auto" scrollbar="hidden">
          <Float offset="48px">
            <Icon
              onClick={() => agreementModal.close()}
              name="close"
              className={css({
                w: '24px',
                h: '24px'
              })}
            />
          </Float>
          <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
      </Modal>

      <Container
        className={vstack({
          pos: 'relative',
          minH: '100svh',
          bg: `linear-gradient(0deg, rgba(182, 46, 255, 1), rgba(74, 28, 153, 1))`,
          p: 0,
          gap: 0
        })}>
        <img
          className={css({
            pos: 'absolute',
            top: '-54px',
            right: 0,
            left: '12px',
            h: '472px'
          })}
          src={bgEffectImage}
        />
        <VStack
          css={{
            zIndex: 1,
            flex: 1,
            h: '100%',
            justifyContent: 'flex-end',
            paddingBottom: '20px'
          }}>
          <VStack
            css={{
              p: '48px 32px',
              rounded: '24px',
              bg: '#FFF',
              boxShadow: '0px 12px 32px 0px rgba(0, 0, 0, 0.12)',
              marginInline: '12px',
              alignItems: 'flex-start',
              gap: '24px'
            }}>
            <Box>
              <h1
                className={css({
                  color: '#000',
                  fontSize: '32px',
                  lineHeight: '38.19px',
                  fontWeight: 590
                })}>
                {t(`routes.signIn.heading`)}
              </h1>
              <span
                className={css({
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '16.71px',
                  color: 'rgba(0, 0, 0, 0.5)'
                })}>
                {t(`routes.signIn.description`)}
              </span>
            </Box>
            <img
              src={connectImage}
              className={css({
                w: '210px'
              })}
            />
            <HStack
              css={{
                h: '54px',
                w: '100%',
                justifyContent: 'center',
                rounded: '8px',
                bgColor: 'rgba(129, 22, 195, 1)',
                color: '#FFF',
                fontSize: '16px',
                fontWeight: 590
              }}
              onClick={async () => {
                if (isPending) return
                setIsPending(true)
                try {
                  await signIn()
                } catch (error) {
                  captureException(error)
                  console.error(error)
                }
                setIsPending(false)
              }}>
              {t(`routes.signIn.telegramSignInButton.label`)}
            </HStack>
            <span
              className={css({
                color: 'rgba(0, 0, 0, 0.4)',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16.71px'
              })}>
              <Trans
                t={t}
                i18nKey={`routes.signIn.agreement.label`}
                components={{
                  agreement: (
                    <Box
                      onClick={() => agreementModal.open()}
                      className={css({
                        display: 'inline',
                        textDecoration: 'underline'
                      })}>
                      {t(`routes.signIn.agreementLink.label`)}
                    </Box>
                  )
                }}
              />
            </span>
          </VStack>
        </VStack>
      </Container>
    </>
  )
}
