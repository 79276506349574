import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { SolanaGlobalClient } from './client'
import type { DigitalAssetWithToken } from '@metaplex-foundation/mpl-token-metadata'
import type { SolanaAddress, SolanaTokenMint } from '@turbx/common'

type AddressesType = SolanaAddress

// https://github.com/lukemorales/query-key-factory

export const solanaQueries = createQueryKeyStore({
  assets: {
    fetchEarlyAccess: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: (): Promise<DigitalAssetWithToken | null> => SolanaGlobalClient.fetchEarlyAccessTokenAccount(owner!),
      enabled: !!owner
    }),
    all: (owners: AddressesType[]) => ({
      queryKey: [{ owners }],
      queryFn: (): Promise<DigitalAssetWithToken[]> => SolanaGlobalClient.fetchMultipleEarlyAccessTokenAccounts(owners)
    }),
    getBalance: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: () => SolanaGlobalClient.getBalance(owner!),
      enabled: !!owner
    }),
    getMultipleBalances: (addresses: [AddressesType, ...AddressesType[]] | undefined) => ({
      queryKey: [addresses],
      queryFn: () => SolanaGlobalClient.getMultipleBalances(addresses!),
      enabled: !!addresses
    }),
    getTokenAccounts: (owner: AddressesType | undefined) => ({
      queryKey: [{ owner }],
      queryFn: () => SolanaGlobalClient.getTokenAccounts(owner!),
      enabled: !!owner
    }),
    tokenMetadatas: (mints: SolanaTokenMint[] | undefined) => ({
      queryKey: [{ mints }],
      queryFn: () => SolanaGlobalClient.tokensMetadata(mints!),
      enabled: !!mints
    })
  }
})
