import { hc, type InferRequestType, type InferResponseType } from 'hono/client'
import type {
  CloudlinkAppType,
  CommunityRoomServerMessage,
  JwtTokens,
  MessageType,
  TokenCommunityBaseType
} from '@turbx/cloudlink'
import { APP_VERSION, CLOUDLINK_API_ENDPOINT } from '../../constant'
import { ensureValidJwtToken } from '../authentication'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  type DefaultError,
  type QueryKey,
  type SetDataOptions,
  type UndefinedInitialDataInfiniteOptions,
  type Updater,
  type UseMutationOptions,
  type UseQueryOptions
} from '@tanstack/react-query'
import { queryClient } from '../query'
import type { CommunityId } from '@turbx/common'

const buildCommonHeaders = (): Record<string, string> => {
  return {
    'x-app-version': APP_VERSION,
    'x-app-source': 'webapp'
  }
}

// https://hono.dev/docs/concepts/stacks#with-react
const cloudlinkClient = hc<CloudlinkAppType>(CLOUDLINK_API_ENDPOINT, {
  headers: async () => {
    const token = await ensureValidJwtToken()

    return {
      ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
      ...buildCommonHeaders()
    }
  }
})

// https://hono.dev/docs/guides/rpc#client

const $cloudlinkApis = {
  // auth
  mockLogin: cloudlinkClient.auth.__DEV_ONLY__mock,
  telegramLogin: cloudlinkClient.auth.telegramLogin,
  refreshToken: cloudlinkClient.auth.refreshToken,

  // account
  profile: cloudlinkClient.accounts.profile,
  points: cloudlinkClient.accounts.points,

  // premium
  paymentGenerate: cloudlinkClient.premium.payment_generate,
  paymentVerify: cloudlinkClient.premium.payment_verify,

  // invitations
  invitationList: cloudlinkClient.invitations.list,
  invitationCreate: cloudlinkClient.invitations.create,
  invitationDelete: cloudlinkClient.invitations.delete,

  // blob
  blobUpload: cloudlinkClient.blobs.upload, // TODO: Currently, the client does not support file uploading.

  // https://hono.dev/docs/helpers/websocket#rpc-mode
  // websocket
  wsCommunity: cloudlinkClient.ws.community,
  wsPersonal: cloudlinkClient.ws.personal,
  wsMatching: cloudlinkClient.ws.matching,

  // token accounts
  tokenAccounts: cloudlinkClient.accounts.tokenAccounts,
  balances: cloudlinkClient.accounts.balances,

  // token
  tokenSearch: cloudlinkClient.sol.token.search,
  tokenPrices: cloudlinkClient.sol.token.prices,

  // wallet
  addressList: cloudlinkClient.sol.address.list,
  addressEntitle: cloudlinkClient.sol.address.entitle,
  addressGenerate: cloudlinkClient.sol.address.generate,
  addressImport: cloudlinkClient.sol.address.import,
  addressRemove: cloudlinkClient.sol.address.remove,
  addressMakeDefault: cloudlinkClient.sol.address.makeDefault,

  withdrawAddressCreate: cloudlinkClient.sol.address.createWithdrawAddress,
  withdrawAddressUpdate: cloudlinkClient.sol.address.updateWithdrawAddress,
  withdrawAddressRemove: cloudlinkClient.sol.address.removeWithdrawAddress,

  // swap
  swapPreflight: cloudlinkClient.sol.transaction.swapPreflightSSE,
  swapSend: cloudlinkClient.sol.transaction.swapSend,
  swapConfirm: cloudlinkClient.sol.transaction.swapConfirm,

  // transfer
  transferPreflight: cloudlinkClient.sol.transaction.transferPreflight,
  transferSend: cloudlinkClient.sol.transaction.transferSend,
  transferConfirm: cloudlinkClient.sol.transaction.transferConfirm,

  // community
  topTokens: cloudlinkClient.communities.topTokens,
  myCommunities: cloudlinkClient.communities.myCommunities,
  communityInfoByMint: cloudlinkClient.communities.infoByMint,
  communityInfoByCommunityId: cloudlinkClient.communities.infoByCommunityId,
  communityCreate: cloudlinkClient.communities.create,
  communityJoin: cloudlinkClient.communities.join,
  communityLeave: cloudlinkClient.communities.leave,
  communityIdentities: cloudlinkClient.communities.communityIdentities,

  // messages && comments
  communityChatRoomMessages: cloudlinkClient.communities.chat_room_messages,
  communityRoomMessageCreate: cloudlinkClient.communities.chat_room_message_send,
  communityComments: cloudlinkClient.communities.comments,
  communityCommentsCreate: cloudlinkClient.communities.comment_send,
  reactionCreate: cloudlinkClient.communities.reactionCreate,
  reactionDelete: cloudlinkClient.communities.reactionDelete
} as const

type CloudlinkApi = typeof $cloudlinkApis

type CloudlinkMutations = {
  [K in keyof CloudlinkApi]: CloudlinkApi[K] extends { $post: unknown } ? K : never
}[keyof CloudlinkApi]

// https://hono.dev/docs/concepts/stacks#with-react

const useCloudlinkMutation = <
  Type extends CloudlinkMutations,
  TData extends InferResponseType<CloudlinkApi[Type]['$post']>,
  Req extends InferRequestType<CloudlinkApi[Type]['$post']>,
  TVariables extends Req extends { json: infer R } ? R : void
>(
  type: Type,
  options?: Omit<UseMutationOptions<TData, Error, TVariables>, 'mutationFn'>
) => {
  return useMutation<TData, Error, TVariables>({
    ...options,
    mutationFn: async (value) =>
      (await (await $cloudlinkApis[type].$post((value ? { json: value } : undefined) as never)).json()) as never
  })
}

type CloudlinkWebsockets = {
  [K in keyof CloudlinkApi]: CloudlinkApi[K] extends { $ws: unknown } ? K : never
}[keyof CloudlinkApi]

const cloudlinkWebsocket = <Type extends CloudlinkWebsockets>(type: Type): CloudlinkApi[Type]['$ws'] => {
  return $cloudlinkApis[type].$ws
}

type CloudlinkQueries = {
  [K in keyof CloudlinkApi]: CloudlinkApi[K] extends { $get: unknown } ? K : never
}[Exclude<keyof CloudlinkApi, CloudlinkInfiniteQueries | CloudlinkWebsockets>]

// https://github.com/orgs/honojs/discussions/3075
// https://github.com/orgs/honojs/discussions/2475#discussioncomment-9073957

const cloudlinkInvalidateQuery = <
  Type extends CloudlinkQueries,
  Req extends InferRequestType<CloudlinkApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables
) => {
  queryClient.invalidateQueries({ queryKey: [type, params] as unknown as TQueryKey })
}

const cloudlinkSetQueryData = <
  Type extends CloudlinkQueries | CloudlinkInfiniteQueries,
  Req extends InferRequestType<CloudlinkApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<CloudlinkApi[Type]['$get']>,
  TData = TQueryFnData
>(
  type: Type,
  params: TVariables,
  updater: Updater<TData | undefined, TData | undefined>,
  options?: SetDataOptions
): TData | undefined => {
  return queryClient.setQueryData<TData>([type, params] as QueryKey, updater, options)
}

const useCloudlinkQuery = <
  Type extends CloudlinkQueries,
  Req extends InferRequestType<CloudlinkApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<CloudlinkApi[Type]['$get']>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [type, params] as unknown as TQueryKey,
    queryFn: async () => {
      const res = await $cloudlinkApis[type].$get({ query: params } as never)
      return (await res.json()) as never
    },
    ...options
  })
}

type CloudlinkInfiniteQueries = 'communityChatRoomMessages' | 'communityComments'

const useCloudlinkInfiniteQuery = <
  Type extends CloudlinkInfiniteQueries,
  Req extends InferRequestType<CloudlinkApi[Type]['$get']>,
  TVariables extends Req extends { query: infer R } ? R : void,
  TQueryFnData = InferResponseType<CloudlinkApi[Type]['$get']>,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  type: Type,
  params: TVariables,
  options: Omit<UndefinedInitialDataInfiniteOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
) => {
  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [type, params] as unknown as TQueryKey,
    queryFn: async () => {
      const res = await $cloudlinkApis[type].$get({ query: params } as never)
      return (await res.json()) as never
    },
    ...options
  })
}

export const cloudlink = {
  useMutation: useCloudlinkMutation,
  useQuery: useCloudlinkQuery,
  useInfiniteQuery: useCloudlinkInfiniteQuery,
  invalidateQuery: cloudlinkInvalidateQuery,
  setQueryData: cloudlinkSetQueryData,
  websocket: cloudlinkWebsocket,
  apiClient: $cloudlinkApis
}

export interface CloudlinkTypes {
  AddressItem: InferResponseType<(typeof $cloudlinkApis.addressList)['$get']>['addresses'][0]
  UserWithTokens: InferResponseType<(typeof $cloudlinkApis.mockLogin)['$post']>
  TokenAccountType: InferResponseType<(typeof $cloudlinkApis.tokenAccounts)['$get']>[0]
  CommunityId: CommunityId
  CommunityType: InferResponseType<(typeof $cloudlinkApis.myCommunities)['$get']>[0]
  MaybeCommunityType: InferResponseType<(typeof $cloudlinkApis.topTokens)['$get']>[0]
  TokenCommunityBaseType: TokenCommunityBaseType
  JwtTokens: JwtTokens
  MessageType: MessageType
  UserMessageType: MessageType['account']
  UserProfileType: InferResponseType<(typeof $cloudlinkApis.profile)['$get']>['account']
  ReactionType: MessageType['reactions'][0]
  TextMessagePayload: Extract<MessageType['payload'], { type: 'Text' }>
  CommunityChatRoomMessages: InferResponseType<(typeof $cloudlinkApis.communityChatRoomMessages)['$get']>['rows']
  CommunityRoomServerMessage: CommunityRoomServerMessage
  SwapSendRequestParams: InferRequestType<(typeof $cloudlinkApis.swapSend)['$post']>['json']
}
