import { Outlet, type RouteComponent, createFileRoute } from '@tanstack/react-router'
import { Navbar } from '@/components'

const NavigableLayout: RouteComponent = () => {
  return (
    <>
      <Outlet />
      <Navbar />
    </>
  )
}

export const Route = createFileRoute('/_authenticated/_navigable')({
  component: NavigableLayout
})
