import { PUBLIC_KEY_LENGTH, PublicKey } from '@solana/web3.js'
import bs58 from 'bs58'
import { z } from 'zod'
import nacl from 'tweetnacl'
import { Buffer } from 'node:buffer'
import type { Web3WalletPrivateKey } from '@turbx/common'

export const naclDecodePhantomPayload = (
  payload: { encryptionPublicKey: string; data: string; nonce: string },
  secretKey: string
): { session: string; public_key: string } => {
  const sharedSecretDapp = nacl.box.before(bs58.decode(payload.encryptionPublicKey), bs58.decode(secretKey))

  const decryptedData = nacl.box.open.after(bs58.decode(payload.data), bs58.decode(payload.nonce), sharedSecretDapp)
  if (!decryptedData) {
    throw new Error('Unable to decrypt data')
  }
  return JSON.parse(Buffer.from(decryptedData).toString('utf8'))
}

export const NaclKeyPairZod = z
  .string()
  .min(32)
  .max(44)
  .refine(
    (v) => isValidNaclPublicKeyOrSecretKey(v),
    (v) => ({ message: `Invalid Nacl Key pair: ${v}` })
  )
  .brand('NaclKeyPair')

export const SolanaAddressZod = z
  .string()
  .min(32)
  .max(44)
  .refine(
    (v) => isValidSolanaWalletOrMint(v),
    (v) => ({ message: `Invalid Solana address: ${v}` })
  )
  .brand('SolanaAddress')

export const SolanaPairZod = z
  .string()
  .min(32)
  .max(44)
  .refine(
    (v) => isValidPair(v),
    (v) => ({ message: `Invalid Solana pool address: ${v}` })
  )
  .brand('SolanaTokenPair')

// TODO: check if the private key is valid
export const isValidSolanaPrivateKey = (privateKey: string): privateKey is Web3WalletPrivateKey => {
  if (!privateKey) return false
  // // Solana private key regex pattern: \b[0-9a-fA-F]{64}\b
  // const privateKeyRegex = /\b[0-9a-fA-F]{64}\b/
  // return privateKeyRegex.test(privateKey)

  return true
}

export const isValidSolanaAddress = <T extends string>(address: string): address is T => {
  if (!address) return false
  try {
    const decoded = bs58.decode(address)
    return decoded.length === PUBLIC_KEY_LENGTH
  } catch (_e) {
    return false
  }
}

export const isValidSolanaWalletOrMint = <T extends string>(address: string): address is T => {
  if (!address) return false
  const isValidAddress = isValidSolanaAddress(address)
  if (!isValidAddress) return false

  const publicKey = new PublicKey(address)
  return PublicKey.isOnCurve(publicKey)
}

export const isValidPair = <T extends string>(address: string): address is T => {
  if (!address) return false
  const isValidAddress = isValidSolanaAddress(address)
  if (!isValidAddress) return false

  const publicKey = new PublicKey(address)
  return !PublicKey.isOnCurve(publicKey)
}

// https://github.com/dchest/tweetnacl-js/blob/master/README.md#naclboxkeypair
export const isValidNaclPublicKeyOrSecretKey = <T extends string>(key: string): key is T => {
  if (!key) return false
  try {
    const decoded = bs58.decode(key)
    return decoded.length === 32
  } catch (_e) {
    return false
  }
}
