import { httpLink, splitLink, type HTTPHeaders } from '@trpc/client'
import { trpc } from './trpc'
import { APP_VERSION } from '../../constant'
import { ensureValidJwtToken } from '../authentication'
import type { TrpcClientContext } from './interface'

const SERVER_API_ENDPOINT = 'http://localhost:3000'

// 迁移 server 依赖
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TrpcHeaders = any

const buildCommonHeaders = (): HTTPHeaders => {
  return {
    'x-app-version': APP_VERSION,
    'x-app-source': 'webapp'
  } satisfies TrpcHeaders
}

export const tRpcClient = trpc.createClient({
  links: [
    splitLink({
      condition(op) {
        return (op.context as TrpcClientContext).skipAuth === true
      },
      true: httpLink({
        url: `${SERVER_API_ENDPOINT}/trpc`,
        headers: buildCommonHeaders()
      }),
      false: httpLink({
        url: `${SERVER_API_ENDPOINT}/trpc`,
        headers: async () => {
          const token = await ensureValidJwtToken()

          return {
            ...(token ? { authorization: `Bearer ${token.accessToken}` } : {}),
            ...buildCommonHeaders()
          }
        }
      })
    })
  ]
})
