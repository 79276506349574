import {
  APP_ENVIRONMENT,
  APP_VERSION,
  ENABLE_DEBUGGER,
  FEATURE_FLAGS,
  KEYPAIR_DAPP_NACL_PUBLIC_KEY,
  KEYPAIR_MINT_PAYER_PUBLIC_KEY,
  KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY,
  SOLANA_FRONTEND_HTTP_ENDPOINT
} from '@/constant'
import eruda from 'eruda'
import buildTime from '~build/time'
import { sha } from '~build/git'

export const enableDebugger = (): void => {
  eruda.init()

  console.info('buildTime', buildTime.toString())
  console.info('sha', sha)
  console.log('envs', {
    KEYPAIR_DAPP_NACL_PUBLIC_KEY,
    KEYPAIR_NFT_EARLY_ACCESS_PUBLIC_KEY,
    KEYPAIR_MINT_PAYER_PUBLIC_KEY,
    FEATURE_FLAGS,
    SOLANA_FRONTEND_HTTP_ENDPOINT,
    APP_VERSION,
    APP_ENVIRONMENT
  })
}

export const initDebugger = (): void => {
  if (ENABLE_DEBUGGER) {
    enableDebugger()
  }
}
