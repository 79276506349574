// https://developers.metaplex.com/token-metadata/mint

import {
  deserializeDigitalAssetWithToken,
  fetchAllDigitalAssetWithTokenByMint,
  fetchAllDigitalAssetWithTokenByOwnerAndMint,
  fetchDigitalAsset,
  fetchDigitalAssetWithAssociatedToken,
  findMasterEditionPda,
  findMetadataPda,
  findTokenRecordPda,
  type DigitalAsset,
  type DigitalAssetWithToken
} from '@metaplex-foundation/mpl-token-metadata'
import { findAssociatedTokenPda } from '@metaplex-foundation/mpl-toolbox'
import { assertAccountExists, chunk, publicKey, type RpcBaseOptions, type Umi } from '@metaplex-foundation/umi'
import type { NFTMintPublicKeyType } from './interface'
import type { SolanaAddress } from '@turbx/common'

// https://developers.metaplex.com/token-metadata/fetch

export const fetchAssetByMint = async (umi: Umi, mint: NFTMintPublicKeyType): Promise<DigitalAsset> => {
  return await fetchDigitalAsset(umi, publicKey(mint))
}

export const fetchAllAssetWithTokens = async (
  umi: Umi,
  mint: NFTMintPublicKeyType
): Promise<DigitalAssetWithToken[]> => {
  return await fetchAllDigitalAssetWithTokenByMint(umi, publicKey(mint))
}

// https://developers.metaplex.com/token-metadata/fetch#fetch-by-mint-and-owner
export const fetchTokenAccount = async (
  umi: Umi,
  mint: NFTMintPublicKeyType,
  owner: SolanaAddress
): Promise<DigitalAssetWithToken | null> => {
  try {
    return await fetchDigitalAssetWithAssociatedToken(umi, publicKey(mint), publicKey(owner))
  } catch (e) {
    if ((e as Error).name === 'AccountNotFoundError') return null
    console.error({ error: e, errorName: (e as Error).name }, 'fetchTokenAccount error')
    throw e
  }
}

// https://developers.metaplex.com/token-metadata/fetch#fetch-all-by-owner-and-mint
export const fetchAllTokenAccount = async (
  umi: Umi,
  mint: NFTMintPublicKeyType,
  owner: SolanaAddress
): Promise<DigitalAssetWithToken[]> => {
  try {
    return await fetchAllDigitalAssetWithTokenByOwnerAndMint(umi, publicKey(owner), publicKey(mint))
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const fetchMultipleMultipleTokenAccounts = async (
  _context: Umi,
  _mint0: NFTMintPublicKeyType,
  _owners: SolanaAddress[],
  _options?: RpcBaseOptions
): Promise<DigitalAssetWithToken[]> => {
  // https://github.com/metaplex-foundation/mpl-token-metadata/blob/main/clients/js/src/digitalAssetWithToken.ts#L100-L119
  throw new Error('Not implemented')
}

// NOTE: in case of comments
// NOTE: only one token account for mint and owner
export const fetchMultipleSingleTokenAccounts = async (
  context: Umi,
  mint0: NFTMintPublicKeyType,
  owners: SolanaAddress[],
  options?: RpcBaseOptions
): Promise<DigitalAssetWithToken[]> => {
  if (owners.length === 0) return []
  // prepare
  const mint = publicKey(mint0)

  const nonEmptyTokens = [...new Set(owners)].map(
    (owner) => findAssociatedTokenPda(context, { mint, owner: publicKey(owner) })[0]
  )

  // https://github.com/metaplex-foundation/mpl-token-metadata/blob/main/clients/js/src/digitalAssetWithToken.ts#L190-L210
  const accountsToFetch = [mint, findMetadataPda(context, { mint })[0], findMasterEditionPda(context, { mint })[0]]
  accountsToFetch.push(...nonEmptyTokens.flatMap((token) => [token, findTokenRecordPda(context, { mint, token })[0]]))

  const accounts = await context.rpc.getAccounts(accountsToFetch, options)
  const [mintAccount, metadataAccount, editionAccount, ...tokenAccounts] = accounts
  assertAccountExists(mintAccount!, 'Mint')
  assertAccountExists(metadataAccount!, 'Metadata')

  return chunk(tokenAccounts, 2).flatMap(([tokenAccount, tokenRecordAccount]): DigitalAssetWithToken[] => {
    if (!tokenAccount?.exists) return []
    return [
      deserializeDigitalAssetWithToken(
        mintAccount,
        metadataAccount,
        tokenAccount,
        editionAccount?.exists ? editionAccount : undefined,
        tokenRecordAccount?.exists ? tokenRecordAccount : undefined
      )
    ]
  })
}
