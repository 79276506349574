import { useCallback, useEffect, useMemo } from 'react'
import { create } from 'zustand'

interface ModalStoreState {
  config: Record<string, boolean | undefined>
  isVisible: (modalId?: string) => boolean
  register: () => string
  unregister: (modalId: string) => void
  open: (modalId: string) => void
  close: (modalId: string) => void
}

let counter = 0

export const useModalStore = create<ModalStoreState>((set, get) => ({
  config: {},
  isVisible: (modalId): boolean => {
    if (modalId) return !!get().config[modalId]
    return Object.values(get().config ?? {}).some((v) => v)
  },
  register: (): string => {
    const modalId = counter.toString()
    counter += 1
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: false
      }
    }))
    return modalId
  },
  unregister: (modalId): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: undefined
      }
    })),
  open: (modalId): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: true
      }
    })),
  close: (modalId): void =>
    set((state) => ({
      ...state,
      config: {
        ...state.config,
        [modalId]: false
      }
    }))
}))

export const useModal = (
  modalId?: string
): {
  modalId: string
  open: () => void
  close: () => void
  unregister: () => void
} => {
  const register = useModalStore((state) => state.register)
  const unregister = useModalStore((state) => state.unregister)
  const close = useModalStore((state) => state.close)
  const open = useModalStore((state) => state.open)

  const currentModalId = useMemo(() => modalId ?? register(), [modalId, register])
  useEffect(() => (): void => unregister(currentModalId), [currentModalId, unregister])

  const handleOpen = useCallback(() => open(currentModalId), [open, currentModalId])
  const handleClose = useCallback(() => close(currentModalId), [currentModalId, close])
  const handleUnregister = useCallback(() => unregister(currentModalId), [unregister, currentModalId])

  return {
    modalId: currentModalId,
    open: handleOpen,
    close: handleClose,
    unregister: handleUnregister
  }
}
