import { type FC } from 'react'
import { useNavbarStore } from './navbar.hook'
import { Icon } from '@turbx/deprecated-design-system'
import { Link } from '@tanstack/react-router'
import { css, type Styles } from '@/styled-system/css'
import { createDataset } from '@/utils'
import { hstack } from '@/styled-system/patterns'
import { HStack, VStack } from '@/styled-system/jsx'
import { useTranslation } from 'react-i18next'

const footerHeight = `58px`
const footerPaddingBottom = `24px`

const iconCss = css({
  w: '24px',
  h: '24px'
})

const navItemCss = css({
  flex: 1
})

const navItemInnerCss: Styles = {
  flex: 1,
  gap: '4px',
  fontSize: '12px',
  lineHeight: '14.32px',
  fontWeight: 400,
  color: 'rgba(171, 171, 171, 1)',
  _active: {
    color: '#000'
  }
}

export const footerPlaceholderCss = css({
  height: footerHeight,
  marginTop: '23px'
})

export const Navbar: FC = () => {
  const { t } = useTranslation()
  const visible = useNavbarStore((state) => state.visible)

  if (!visible) return null

  return (
    <footer
      className={hstack({
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
      })}>
      <HStack
        css={{
          w: '100%',
          height: `calc(${footerHeight} + ${footerPaddingBottom})`,
          padding: `8px 16px ${footerPaddingBottom}`,
          bgColor: '#FFF'
        }}>
        <Link className={navItemCss} to="/">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'home-fill' : 'home-line'} />
              {t('common.navbar.home.label')}
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/ranking">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'bubble-chart-fill' : 'bubble-chart-line'} />
              {t('common.navbar.ranking.label')}
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'coin-fill' : 'coin-line'} />
              {t('common.navbar.trade.label')}
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/quest">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'user-fill' : 'user-line'} />
              {t('common.navbar.invite.label')}
            </VStack>
          )}
        </Link>
        <Link className={navItemCss} to="/wallet">
          {({ isActive }) => (
            <VStack {...createDataset({ 'data-active': isActive })} css={navItemInnerCss}>
              <Icon className={iconCss} name={isActive ? 'wallet-fill' : 'wallet-line'} />
              {t('common.navbar.wallet.label')}
            </VStack>
          )}
        </Link>
      </HStack>
    </footer>
  )
}
