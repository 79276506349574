/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AxiosDefaults, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'

// https://github.com/raydium-io/raydium-sdk-V2/blob/master/src/api/api.ts#L101

export function createRaydiumAxiosInstance(config: AxiosRequestConfig = {}): AxiosInstance {
  const defaults: AxiosDefaults = {
    baseURL: config.baseURL || '',
    timeout: config.timeout,
    headers: {
      common: {
        'Content-Type': 'application/json',
        ...(config.headers || {})
      },
      delete: {},
      get: {},
      head: {},
      post: {},
      put: {},
      patch: {}
    }
  }

  const instance = function (config: AxiosRequestConfig): Promise<AxiosResponse> {
    return instance.request(config)
  } as AxiosInstance

  instance.defaults = defaults as any

  const request: any = async <T = any>(
    configOrUrl: string | AxiosRequestConfig,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    let url: string
    let finalConfig: AxiosRequestConfig

    if (typeof configOrUrl === 'string') {
      url = configOrUrl
      finalConfig = config || {}
    } else {
      finalConfig = configOrUrl
      url = finalConfig.url || ''
    }

    const fullUrl = new URL(url, defaults.baseURL)

    const method = (finalConfig.method || 'GET').toUpperCase() as Method
    const headers = { ...defaults.headers, ...finalConfig.headers }

    const fetchOptions: RequestInit = { method, headers: headers as any }

    if (finalConfig.data) {
      fetchOptions.body = JSON.stringify(finalConfig.data)
    }

    if (finalConfig.params) {
      const searchParams = new URLSearchParams(finalConfig.params)
      fullUrl.search += (fullUrl.search ? '&' : '') + searchParams.toString()
    }

    const timeout = finalConfig.timeout || defaults.timeout
    const controller = new AbortController()
    fetchOptions.signal = controller.signal

    const timeoutPromise = timeout
      ? new Promise<never>((_resolve, reject) =>
          setTimeout(() => {
            controller.abort()
            reject(new Error('Request timeout'))
          }, timeout)
        )
      : null

    try {
      const fetchPromise = fetch(fullUrl.toString(), fetchOptions)
      const response = await (timeoutPromise ? Promise.race([fetchPromise, timeoutPromise]) : fetchPromise)

      if (!response.ok) {
        const error: AxiosError = new Error('Request failed') as AxiosError
        error.config = finalConfig as any
        error.request = fetchOptions
        error.response = {
          data: await response.text(),
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers),
          config: finalConfig as any
        }
        error.isAxiosError = true
        throw error
      }

      const data = (await response.json()) as any

      const axiosResponse: AxiosResponse<T> = {
        data: data.data,
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers),
        config: finalConfig as any
      }

      return axiosResponse
    } catch (e) {
      if ((e as any).name === 'AbortError') {
        throw new Error('Request timeout')
      }
      throw e
    }
  }

  instance.request = request

  instance.delete = (<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return instance.request({
      ...config,
      method: 'DELETE',
      url
    })
  }) as any

  instance.post = (<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return instance.request({
      ...config,
      method: 'POST',
      url,
      data
    })
  }) as any

  instance.put = (<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return instance.request({
      ...config,
      method: 'PUT',
      url,
      data
    })
  }) as any

  instance.patch = (<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return instance.request({
      ...config,
      method: 'PATCH',
      url,
      data
    })
  }) as any

  instance.get = (<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return instance.request({
      ...config,
      method: 'GET',
      url
    })
  }) as any

  return instance
}
